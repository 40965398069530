<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('email-template-modal', {id: 0},shown,{})"
                        variant="primary"
                        class="mb-2 mr-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                    <b-button @click="$root.$children[0].openModal('test-email-modal', {},null,{})"
                              variant="warning"
                              class="mb-2"
                    >
                        {{ $t('common.button.test') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.email_template.label.id') }}</b-th>
                            <b-th>{{ $t('settings.email_template.label.name') }}</b-th>
                            <b-th>{{ $t('settings.email_template.label.subject') }}</b-th>
                            <b-th>{{ $t('settings.email_template.label.user') }}</b-th>
                            <b-th>{{ $t('settings.email_template.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="template in templates" :key="template.id">
                            <b-td>{{ template.id }}</b-td>
                            <b-td>{{ template.name }}</b-td>
                            <b-td>{{ template.subject }}</b-td>
                            <b-td>
                                <div v-if="template.user">{{ template.user.email }}</div>
                                <CBadge v-else color="success">Global</CBadge>
                            </b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('email-template-modal', {id: template.id},shown,{})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteTemplate(template.id)"
                                          size="sm"
                                          variant="danger"
                                          v-if="!template.is_system"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-email-templates',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            templates: [],
        }
    },
    methods: {
        ...mapGetters('EmailTemplate', ['getEmailTemplates']),
        shown() {
            const templatesFetch = this.$store.dispatch('EmailTemplate/fetchTemplates')

            Promise.all([templatesFetch])
                .then(() => {
                    this.templates = this.getEmailTemplates()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteTemplate(id) {
            this.$removeConfirm('EmailTemplate/deleteTemplate', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>